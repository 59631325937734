export const lmic =
    [
        "Angola",
        "Bangladesh",
        "Bhutan",
        "Bolivia",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Comoros",
        "Congo, the Democratic Republic of the",
        "Côte d'Ivoire",
        "Djibouti",
        "Egypt, Arab Rep.",
        "Egypt",
        "El Salvador",
        "Eswatini",
        "Ghana",
        "Honduras",
        "India",
        "Indonesia",
        "Kenya",
        "Kiribati",
        "Kyrgyz Republic",
        "Lao PDR",
        "Lesotho",
        "Mauritania",
        "Micronesia, Fed. Sts.",
        "Moldova",
        "Mongolia",
        "Morocco",
        "Myanmar",
        "Nicaragua",
        "Nigeria",
        "Pakistan",
        "Papua New Guinea",
        "Philippines",
        "São Tomé and Principe",
        "Senegal",
        "Solomon Islands",
        "Sri Lanka",
        "Sudan",
        "Timor-Leste",
        "Tunisia",
        "Ukraine",
        "Uzbekistan",
        "Vanuatu",
        "Vietnam",
        "West Bank and Gaza",
        "Zambia",
        "Zimbabwe",
        "Afghanistan",
        "Benin",
        "Burkina Faso",
        "Burundi",
        "Central African Republic",
        "Chad",
        "Congo, Dem. Rep.",
        "Eritrea",
        "Ethiopia",
        "Gambia",
        "Guinea",
        "Guinea-Bissau",
        "Haiti",
        "North Korea",
        "Liberia",
        "Madagascar",
        "Malawi",
        "Mali",
        "Mozambique",
        "Nepal",
        "Niger",
        "Rwanda",
        "Sierra Leone",
        "Somalia",
        "South Sudan",
        "Syrian Arab Republic",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Togo",
        "Uganda",
        "Yemen, Rep.",
    ]
