import {
    CHANGE_DOCUMENTS,
    CHANGE_INFORMATION,
    CHANGE_PROPERTY, DELETE_FILE,
    RESET_OPTIONS, RESET_REGISTRATION, RESET_STRIPE,
    SELECT_OPTIONS,
    SELECT_TICKET,
    SET_ERROR, SUBMIT_DOCUMENTS,
    SUBMIT_INFORMATION, SUBMIT_STRIPE_ERROR, SUBMIT_STRIPE_SUCCESS
} from "./mediaregistrationAction"
import {GET_CATEGORY, GET_OPTIONS, RECORD_USER_SUCCESSFUL, SEND_FILES_SUCCESSFUL} from "../api/apiAction"

const initialState = {
    formValidated: false,
    documentsValidated: false,
    information: {
        single: true,
        unionMember: false,
        registered_gender: {
            error: '',
            value: '',
            required: false,
        },
        registered_lastname: {
            error: '',
            value: '',
            required: true,
        },
        registered_firstname: {
            error: '',
            value: '',
            required: true,
        },

        registered_mail: {
            error: '',
            value: '',
            required: true,
        },
        confirmEmail: {
            error: '',
            value: '',
            required: true,
        },
        registered_phone: {
            error: '',
            value: '',
        },
        registered_adress1: {
            error: '',
            value: '',
            required: true,
        },
        registered_adress2: {
            error: '',
            value: '',
            required: false,
        },
        registered_zipcode: {
            error: '',
            value: '',
            required: true,
        },
        registered_city: {
            error: '',
            value: '',
            required: true,
        },
        registered_country: {
            error: '',
            value: '',
            required: true,
        },
        countryCode: {
            error: '',
            value: '',
            required: false,
        },
        registered_organisation: {
            error: '',
            value: '',
            required: true,
        },
        registered_department: {
            error: '',
            value: '',
            required: false,
        },
        registered_function: {
            error: '',
            value: '',
            required: true,
        },
        professionalCtgSelect: {
            error: '',
            value: '',
            required: false,
        },
        registered_profession_category: {
            error: '',
            value: '',
            required: false,
        },

        registered_sex: {
            error: '',
            value: '',
            required: false,
        },
        registered_under: {
            error: '',
            value: false,
            required: true,
        },

        registered_interest: {
            error: '',
            value: [],
            required: true,
        },
        registered_intouch: {
            error: '',
            value: [],
            required: false,
        },
        registered_cvg: {
            error: '',
            value: false,
            required: true,
        },
        tb_accept: {
            error: '',
            value: false,
            required: false,
        },
        registered_file: {
            error: '',
            value: '',
            required: true,
        }
    },
    documents: {
        registered_invit_letter: {
            error: '',
            value: false,
            required: true,
        },
        registered_passportfirstname: {
            error: '',
            value: "",
            required: false,
        },
        registered_passportlastname: {
            error: '',
            value: "",
            required: false,
        },
        registered_passportnb: {
            error: '',
            value: "",
            required: false,
        },
        registered_passport_date: {
            error: '',
            value: "",
            required: false,
        },
        registered_passport_nato: {
            error: '',
            value: "",
            required: false,
        },
        registered_birth: {
            error: '',
            value: "",
            required: false,
        },

        differentInvoiceAdress: {
            error: '',
            value: false,
            required: true,
        },
        registered_society_name: {
            error: '',
            value: '',
            required: false,
        },
        registered_society_adress: {
            error: '',
            value: "",
            required: false,
        },
        registered_society_city: {
            error: '',
            value: "",
            required: false,
        },
        registered_society_cp: {
            error: '',
            value: "",
            required: false,
        },
        registered_society_country: {
            error: '',
            value: "",
            required: false,
        },
        registered_society_vat: {
            error: '',
            value: "",
            required: false,
        },
    },
    category: null,
    ticket: null,
    options: [],
    stripe: {
        error: "",
        success: false,
        publishableKey: "",
        clientSecret: "",
    }
}

const mediaregistrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PROPERTY:
            return {
                ...state,
                information: {
                    ...state.information,
                    [action.name]: {
                        ...state.information[action.name],
                        value: action.value
                    }
                }
            }
        case CHANGE_INFORMATION:
            const name  = action.isInterest ? "registered_interest" : "registered_intouch"
            const index = state.information[name].value.indexOf(action.value)

            if(index !== -1) {
                const array = [...state.information[name].value]
                array.splice(index, 1)

                return {
                    ...state,
                    information: {
                        ...state.information,
                        [name]: {
                            ...state.information[name],
                            value: array
                        }
                    }
                }
            }
            else {
                return {
                    ...state,
                    information: {
                        ...state.information,
                        [name]: {
                            ...state.information[name],
                            value: [...state.information[name].value.concat(action.value)]
                        }
                    }
                }
            }
        case CHANGE_DOCUMENTS:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    [action.name]: {
                        ...state.documents[action.name],
                        value: action.value
                    }
                }
            }
        case SET_ERROR:
            return {
                ...state,
                information: {
                    ...state.information,
                    [action.name]: {
                        ...state.information[action.name],
                        error: action.error
                    }
                }
            }
        case SUBMIT_INFORMATION:
            return {
                ...state,
                formValidated: action.valid
            }
        case SUBMIT_DOCUMENTS:
            return {
                ...state,
                documentsValidated: action.valid
            }
        case GET_CATEGORY:
            return action.isParent === false ?
                {
                    ...state,
                    category: action.category,
                }
                : state
        case SELECT_TICKET:
            return {
                ...state,
                ticket: action.ticket,
                options: []
            }
        case SELECT_OPTIONS:
            if(action.add === true) {
                const index = state.options.findIndex(option => option.idoptions === action.option.idoptions)
                if(index === -1)
                    return {
                        ...state,
                        options: [...state.options.concat(action.option)]
                    }
                else
                    return state
            }
            else {
                const index = state.options.findIndex(option => option.idoptions === action.option.idoptions)
                if(index !== -1) {
                    const array = [...state.options]
                    array.splice(index, 1)

                    return {
                        ...state,
                        options: array
                    }
                }
                return state
            }
        /*case GET_OPTIONS:
            const options = action.options.filter(option => option.included === 1)
            return {
                ...state,
                options: options
            }*/
        case RESET_OPTIONS:
            return {
                ...state,
                options: []
            }
        case RECORD_USER_SUCCESSFUL:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    publishableKey: action.data.publishableKey,
                    clientSecret: action.data.clientSecret
                }
            }
        case SUBMIT_STRIPE_SUCCESS:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    error: "",
                    success: true,
                }
            }
        case SUBMIT_STRIPE_ERROR:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    success: false,
                    error: action.error,
                }
            }
        case RESET_STRIPE:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    success: false,
                    error: "",
                }
            }
        case RESET_REGISTRATION:
            return initialState
                case SEND_FILES_SUCCESSFUL:
            return {
                ...state,
                information: {
                    ...state.information,
                    registered_file: {
                        ...state.information.registered_file,
                        value: action.uri
                    },
                }
            }
        case DELETE_FILE:
            return {
                ...state,
                information: {
                    ...state.information,
                    registered_file: {
                        ...state.information.registered_file,
                        value: "",
                    }
                }
            }
        default:
            return state
    }
}

export default mediaregistrationReducer




