import {
    CHANGE_DOCUMENTS_ADD_MEMBER,
    CHANGE_INFORMATION_ADD_MEMBER,
    CHANGE_PROPERTY_ADD_MEMBER,
    DELETE_FILE_ADD_MEMBER,
    RESET_OPTIONS_ADD_MEMBER,
    RESET_REGISTRATION_ADD_MEMBER,
    SELECT_OPTIONS_ADD_MEMBER,
    SELECT_TICKET_ADD_MEMBER,
    SET_ERROR_ADD_MEMBER, START_ADD_MEMBER, START_EDIT_MEMBER, SUBMIT_DOCUMENTS_ADD_MEMBER,
    SUBMIT_INFORMATION_ADD_MEMBER,
} from "./addMemberAction"
import {GET_CATEGORY, GET_OPTIONS, RECORD_USER_SUCCESSFUL, SEND_FILES_SUCCESSFUL} from "../api/apiAction"

const initialState = {
    onEdit: false,
    formValidated: false,
    documentsValidated: false,
    information: {
        single: true,
        unionMember: false,
        registered_gender: {
            error: '',
            value: '',
            required: true,
        },
        registered_lastname: {
            error: '',
            value: '',
            required: true,
        },
        registered_firstname: {
            error: '',
            value: '',
            required: true,
        },

        registered_mail: {
            error: '',
            value: '',
            required: true,
        },
        confirmEmail: {
            error: '',
            value: '',
            required: true,
        },
        registered_phone: {
            error: '',
            value: '',
        },
        registered_adress1: {
            error: '',
            value: '',
            required: true,
        },
        registered_adress2: {
            error: '',
            value: '',
            required: false,
        },
        registered_zipcode: {
            error: '',
            value: '',
            required: true,
        },
        registered_city: {
            error: '',
            value: '',
            required: true,
        },
        registered_country: {
            error: '',
            value: '',
            required: true,
        },
        countryCode: {
            error: '',
            value: '',
            required: false,
        },
        registered_organisation: {
            error: '',
            value: '',
            required: true,
        },
        registered_department: {
            error: '',
            value: '',
            required: true,
        },
        registered_function: {
            error: '',
            value: '',
            required: true,
        },
        professionalCtgSelect: {
            error: '',
            value: '',
            required: true,
        },
        registered_profession_category: {
            error: '',
            value: '',
            required: true,
        },

        registered_sex: {
            error: '',
            value: '',
            required: false,
        },
        registered_under: {
            error: '',
            value: false,
            required: true,
        },

        registered_interest: {
            error: '',
            value: [],
            required: true,
        },
        registered_intouch: {
            error: '',
            value: [],
            required: true,
        },
        registered_cvg: {
            error: '',
            value: false,
            required: true,
        },
        tb_accept: {
            error: '',
            value: false,
            required: false,
        },
        registered_file: {
            error: '',
            value: '',
            required: false,
        }
    },
    documents: {
        registered_invit_letter: {
            error: '',
            value: false,
            required: true,
        },
        registered_passportnb: {
            error: '',
            value: "",
            required: false,
        },
        registered_passport_date: {
            error: '',
            value: "",
            required: false,
        },
        registered_passport_nato: {
            error: '',
            value: "",
            required: false,
        },
        registered_birth: {
            error: '',
            value: "",
            required: false,
        },
    },
    category: null,
    ticket: null,
    options: [],
}

const addMemberReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PROPERTY_ADD_MEMBER:
            return {
                ...state,
                information: {
                    ...state.information,
                    [action.name]: {
                        ...state.information[action.name],
                        value: action.value
                    }
                }
            }
        case CHANGE_INFORMATION_ADD_MEMBER:
            const name  = action.isInterest ? "registered_interest" : "registered_intouch"
            const index = state.information[name].value.indexOf(action.value)

            if(index !== -1) {
                const array = [...state.information[name].value]
                array.splice(index, 1)

                return {
                    ...state,
                    information: {
                        ...state.information,
                        [name]: {
                            ...state.information[name],
                            value: array
                        }
                    }
                }
            }
            else {
                return {
                    ...state,
                    information: {
                        ...state.information,
                        [name]: {
                            ...state.information[name],
                            value: [...state.information[name].value.concat(action.value)]
                        }
                    }
                }
            }
        case CHANGE_DOCUMENTS_ADD_MEMBER:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    [action.name]: {
                        ...state.documents[action.name],
                        value: action.value
                    }
                }
            }
        case SET_ERROR_ADD_MEMBER:
            return {
                ...state,
                information: {
                    ...state.information,
                    [action.name]: {
                        ...state.information[action.name],
                        error: action.error
                    }
                }
            }
        case SUBMIT_INFORMATION_ADD_MEMBER:
            return {
                ...state,
                formValidated: action.valid
            }
        case SUBMIT_DOCUMENTS_ADD_MEMBER:
            return {
                ...state,
                documentsValidated: action.valid
            }
        case GET_CATEGORY:
            return action.isParent === false ?
                {
                    ...state,
                    category: action.category,
                }
                : state
        case SELECT_TICKET_ADD_MEMBER:
            return {
                ...state,
                ticket: action.ticket,
                options: []
            }
        case SELECT_OPTIONS_ADD_MEMBER:
            if(action.add === true) {
                const index = state.options.findIndex(option => option.idoptions === action.option.idoptions)
                if(index === -1)
                    return {
                        ...state,
                        options: [...state.options.concat(action.option)]
                    }
                else
                    return state
            }
            else {
                const index = state.options.findIndex(option => option.idoptions === action.option.idoptions)
                if(index !== -1) {
                    const array = [...state.options]
                    array.splice(index, 1)

                    return {
                        ...state,
                        options: array
                    }
                }
                return state
            }
        case RESET_OPTIONS_ADD_MEMBER:
            return {
                ...state,
                options: []
            }
        case RECORD_USER_SUCCESSFUL:
            return {
                ...state,
            }
        case RESET_REGISTRATION_ADD_MEMBER:
            return initialState
        case SEND_FILES_SUCCESSFUL:
            return {
                ...state,
                information: {
                    ...state.information,
                    registered_file: {
                        ...state.information.registered_file,
                        value: action.uri
                    },
                }
            }
        case DELETE_FILE_ADD_MEMBER:
            return {
                ...state,
                information: {
                    ...state.information,
                    registered_file: {
                        ...state.information.registered_file,
                        value: "",
                    }
                }
            }
        case START_EDIT_MEMBER:
            return {
                ...state,
                onEdit: true,
            }
        case START_ADD_MEMBER:
            return {
                ...state,
                onEdit: false,
            }
        default:
            return state
    }
}

export default addMemberReducer


