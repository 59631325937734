import { combineReducers } from 'redux'

import apiReducer from "./api/apiReducer"
import registrationReducer from "./registration/registrationReducer"
import freeregistrationReducer from "./free/freeregistrationReducer"
import mediaregistrationReducer from "./media/mediaregistrationReducer"
import tabsReducer from "./tabs/tabsReducer"
import groupTabsReducer from "./tabs/groupTabsReducer"
import groupReducer from "./group/groupReducer"
import addMemberReducer from "./addMember/addMemberReducer"

export default combineReducers({
    apiReducer,
    registrationReducer,
    freeregistrationReducer,
    mediaregistrationReducer,
    tabsReducer,
    groupTabsReducer,
    groupReducer,
    addMemberReducer
})
